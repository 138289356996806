// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper core and required modules
import { Navigation, Pagination, Autoplay, EffectCoverflow } from 'swiper/modules';


// Import Swiper styles
import 'swiper/css';
import Cards from './Cards';

import health from '../assets/CGI/health.png'; 



const LayeredSLider = () => {
  return (
    <Swiper
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      spaceBetween={0}

      loop={true}
    slidesPerView={2}
      coverflowEffect={{
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 2.5,
        loop: true
      }}
      autoplay={{ delay: 2500, disableOnInteraction: false }} // Auto play with a delay of 3000ms (3 seconds)
      pagination={{ el: '.swiper-pagination', clickable: true }}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        clickable: true,
      }}
      modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
      className="swiper_container shadow-xl "
    >



      < SwiperSlide>
        <Cards
          img={'https://s3-alpha-sig.figma.com/img/6d26/04b7/0400cbbf6766976483ca7f45830a8bc6?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pPcov7ZHx7LAEtDsluj11tf5FAWXUG5xXL6W6SEMdyX27riJQlYb8i48DVrQi42HpMCXKGyw5JCr8nphlj3GgdvWzmuj-Njw5JyD9k1O7ufXUv8Ss7YVeEeK96BgZifiVIzfQHWRnaKIYkwTBxrwQjwqmUKSml0NLMjsx8r8WdyRcFBdhL97wJRl5-W8ZKFDzpMz7EnGiukiLa~L~s7P7a3xMxFcNoYWtZnmxlYjiIXhLpwJaQVDmRJo2NOO1isPyQZmDsCrv3HY4-2ed8lLj8avsBxN6yv4Fdi5-VO-Hi~yVhMk5AMGXncw2kLhlZozI2fr-Mb950I0A8hdbK9eMg__'}
          heading={"Web Developmenet"}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Cards
          img={'https://s3-alpha-sig.figma.com/img/2074/3379/80e800086e457836f2d04edfdc319325?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Ova8-ZZPehchYA4sG0Qi~wXRktNBAaKuYE0Oo86jWObrIBCCvyCGeVv~HBlSwW~01NorC7qGWMM~v1BUHH5vSMVKHDq2Z-2u2GTToADxxpiaZdUv2ypkob1dFIgAAUO0Da1Qg6wf-sz0WhIeFnPU32mjnOmM~M7UE8tNJHOO9E-KUjDn0m7~AoxG93ROSQmS6XJsxPGyirfMktkH-oi1uLe8zN-H9QHjoaV4MK1KkoM9KeJsP~cXAPRa8fSEH5VlvUX33GR-LPAnSwKmsQ9fpFeKAXclHKB39I6~jAYaaJwJ8qJYqoG5fbByHSGprcBo3eeJZwUPqUtF5-ybYPyLKw__'}
          heading={"Mobile App Developmenet"}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Cards
          img={'https://s3-alpha-sig.figma.com/img/0963/877c/11a45e3c3baeacb0a0e1b931ccf68964?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=P6XfBG9Y0GwIfHEaD3M38kazgjXpnh3LWALjVmWtXRD~X66Jqq-5if08kByjoCnxAnQK8TtaO~XG71RR9RKULTr2ZIebD4a-Zf~hG9DY86wEarEIR9DEsDvtBudZ3NP-Ez7Nl10o6IQQPu3XCwHC8I1-Xe5ix3alYazKh9mUxTPPlFHc75l0NTiFG6gG1p4C5uTKUdJHiVUxhEUo3t5qotJo3HycrVMWgJ93QLVNlon5CbNCBYKnluRcx0yj3b2aesex5b6ywDWqwJ0ZilaCcMgKaVvNWm2-a0hx~0ZnfXR6OMig9yWzipkDpSIwPUKTb~2vFB3cqv2P5wvLvhSnIw__'}
          heading={"Web Developmenet"}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Cards
          img={'https://s3-alpha-sig.figma.com/img/6d26/04b7/0400cbbf6766976483ca7f45830a8bc6?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pPcov7ZHx7LAEtDsluj11tf5FAWXUG5xXL6W6SEMdyX27riJQlYb8i48DVrQi42HpMCXKGyw5JCr8nphlj3GgdvWzmuj-Njw5JyD9k1O7ufXUv8Ss7YVeEeK96BgZifiVIzfQHWRnaKIYkwTBxrwQjwqmUKSml0NLMjsx8r8WdyRcFBdhL97wJRl5-W8ZKFDzpMz7EnGiukiLa~L~s7P7a3xMxFcNoYWtZnmxlYjiIXhLpwJaQVDmRJo2NOO1isPyQZmDsCrv3HY4-2ed8lLj8avsBxN6yv4Fdi5-VO-Hi~yVhMk5AMGXncw2kLhlZozI2fr-Mb950I0A8hdbK9eMg__'}
          heading={"Web Developmenet"}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Cards
          img={'https://s3-alpha-sig.figma.com/img/2074/3379/80e800086e457836f2d04edfdc319325?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Ova8-ZZPehchYA4sG0Qi~wXRktNBAaKuYE0Oo86jWObrIBCCvyCGeVv~HBlSwW~01NorC7qGWMM~v1BUHH5vSMVKHDq2Z-2u2GTToADxxpiaZdUv2ypkob1dFIgAAUO0Da1Qg6wf-sz0WhIeFnPU32mjnOmM~M7UE8tNJHOO9E-KUjDn0m7~AoxG93ROSQmS6XJsxPGyirfMktkH-oi1uLe8zN-H9QHjoaV4MK1KkoM9KeJsP~cXAPRa8fSEH5VlvUX33GR-LPAnSwKmsQ9fpFeKAXclHKB39I6~jAYaaJwJ8qJYqoG5fbByHSGprcBo3eeJZwUPqUtF5-ybYPyLKw__'}
          heading={"Web Developmenet"}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Cards
          img={health}
          heading={"Web Developmenet"}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Cards
          img={'https://s3-alpha-sig.figma.com/img/6d26/04b7/0400cbbf6766976483ca7f45830a8bc6?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pPcov7ZHx7LAEtDsluj11tf5FAWXUG5xXL6W6SEMdyX27riJQlYb8i48DVrQi42HpMCXKGyw5JCr8nphlj3GgdvWzmuj-Njw5JyD9k1O7ufXUv8Ss7YVeEeK96BgZifiVIzfQHWRnaKIYkwTBxrwQjwqmUKSml0NLMjsx8r8WdyRcFBdhL97wJRl5-W8ZKFDzpMz7EnGiukiLa~L~s7P7a3xMxFcNoYWtZnmxlYjiIXhLpwJaQVDmRJo2NOO1isPyQZmDsCrv3HY4-2ed8lLj8avsBxN6yv4Fdi5-VO-Hi~yVhMk5AMGXncw2kLhlZozI2fr-Mb950I0A8hdbK9eMg__'}
          heading={"Web Developmenet"}
        />
      </SwiperSlide>

      <div className="slider-controler">
        <div className="swiper-button-prev slider-arrow">
          <ion-icon name="arrow-back-outline"></ion-icon>
        </div>
        <div className="swiper-button-next slider-arrow">
          <ion-icon name="arrow-forward-outline"></ion-icon>
        </div>
        <div className="swiper-pagination"></div>
      </div>

    </Swiper>

  )
}

export default LayeredSLider